import selectStorage from '../utils/selectStorage';

interface RemoveItemArgs {
  key: string;
  errorMessage?: string;
  storage: 'local' | 'session';
}

export default function removeItem({ key, storage, errorMessage }: RemoveItemArgs) {
  try {
    if (typeof window !== 'undefined') {
      selectStorage(storage).removeItem(key);
    }
  } catch (error) {
    console.error(`${errorMessage ? `${errorMessage}: ` : ''}${(error as Error).message}`);
  }
}
