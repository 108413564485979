import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  delimiter: {
    marginBottom: space(2),
    ...border({
      side: 'bottom',
      width: '1px',
      style: 'solid',
      color: color('neutral300'),
      spacing: 4,
    }),
  },
});

export default function ControlsDelimeter() {
  return <span className={s9(c.delimiter)} />;
}
