'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageRemove from '@haaretz/s-browser-storage-utils/removeItem';
import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';
import Checkbox from '@haaretz/s-checkbox';
import { appScopeName } from '@haaretz/s-consts';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

type ToggleAudioReader = (action: 'show' | 'hide') => void;

// `c` is short for `classNames`
const c = s9.create({
  audioReaderCbWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  audioReaderLabel: {
    ...typesetter(1),
    ...merge({
      ...mq({
        from: 's',
        value: {
          ...typesetter(-1),
        },
      }),
      ...mq({
        from: 'xxl',
        value: { ...typesetter(-2) },
      }),
    }),
  },
  mobileDmTxt: {
    fontWeight: 700,
  },
});

const AUDIO_READER_TOGGLE_KEY = 'htzToggleAudioReader';

export default function AudioReader() {
  const [isShowAudioReader, setIsShowAudioReader] = React.useState(false);

  React.useEffect(() => {
    const toggleAudioReader = browserStorageGetItem({
      key: AUDIO_READER_TOGGLE_KEY,
      storage: 'local',
    });

    const isShow = toggleAudioReader === 'show';
    setIsShowAudioReader(isShow);
  }, []);

  const biAction = useBi();

  return (
    <Checkbox
      appearance="toggle"
      styleExtend={[c.audioReaderCbWrapper, c.mobileDmTxt]}
      labelStyleExtend={[c.audioReaderLabel]}
      checked={isShowAudioReader}
      onChange={() => {
        const toggleAudioReader = browserStorageGetItem({
          key: AUDIO_READER_TOGGLE_KEY,
          storage: 'local',
        });

        const isShow = toggleAudioReader === 'show';

        biAction({
          campaign_name: 'article audio reader',
          feature: 'Account Ruler on Header',
          feature_type: 'Personal',
          campaign_details: `audio reader ${isShow ? 'off' : 'on'}`,
          action_id: 169,
        });

        (window[appScopeName]?.Iphone.toggleAudioReader as ToggleAudioReader)(
          isShow ? 'hide' : 'show'
        );

        isShow
          ? browserStorageRemove({
              key: AUDIO_READER_TOGGLE_KEY,
              storage: 'local',
            })
          : browserStorageSetItem({
              key: AUDIO_READER_TOGGLE_KEY,
              value: 'show',
              storage: 'local',
            });
      }}
    >
      {fork({ default: 'הפעל מקריא כתבות', hdc: 'Start reading article' })}
    </Checkbox>
  );
}
